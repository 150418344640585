/**
 * @name: 菜单store
 * @author: itmobai
 * @date: 2023-02-17 15:09
 * @description：菜单store
 * @update: 2023-02-17 15:09
 */
import router from '@/router';
import {getMenuApi} from '@/apis/core';
import {generateRoutes} from '@/utils/common';
// @ts-ignore
import menusList from '../../mock/menus'

const state = {
  addRouters: [],
  menuList: menusList,
  menuType: 0
}
const mutations = {
  SET_MENUS(state: any, menus: any) {
    state.menuList = menus;
  },
  getMenuList(state: any) {
    return  state.menuList
  },
}
const actions = {
  // getMenuRole(ctx: any) {
  //   return new Promise(resolve => {
  //     getMenuApi().then(e => {
  //         let route =
  //           {
  //             id: 0,
  //             pid: 0,
  //             sort: 1,
  //             path: "/home",
  //             name: "home",
  //             redirect: null,
  //             menuType: null,
  //             component: "/home/index.vue",
  //             meta: {
  //               title: "主页",
  //               isLink: null,
  //               isHide: false,
  //               isKeepAlive: false,
  //               isAffix: false,
  //               isIframe: false,
  //               auth: "admin",
  //               icon: null
  //             },
  //             children: []
  //           }
  //         let menus = [route, ...e] || [route];
  //       console.log(menus,'menus')
  //         ctx.commit("SET_MENUS", menus)
  //         router.addRoutes(generateRoutes(menus))
  //         resolve(null)
  //       }
  //     )
  //   })
  // },
  GenerateRoutes(routeList: any) {
    const routes: any = [];
    routeList.forEach((item: any) => {
      const route: any = {};
      route.path = item.path;
      route.name = item.name;
      if (item.pid === 0) {
        route.component =() => import('@/layout/index.vue');
      } else {
        route.component =() => import(`@/views${item.component}`);
      }
      route.meta = {
        title: item.meta.title,
        icon: item.meta.icon
      };
      if (item.children) {
        route.children = this.GenerateRoutes(item.children);
      }
      routes.push(route);
    });
    return routes;
  },
  getMenuRole() {
    // 获取远程菜单结果
    state.addRouters=this.GenerateRoutes(menusList);
    return state.addRouters
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
