<template>
  <div id="global-uploader" :class="{'global-uploader-single': !global}">
    <!-- 上传 -->
    <uploader
      ref="uploader"
      :options="initOptions"
      :fileStatusText="fileStatusText"
      :autoStart="false"
      @file-added="onFileAdded"
      @file-success="onFileSuccess"
      @file-progress="onFileProgress"
      @file-error="onFileError"
      @file-removed="onFileRemoved"
      class="uploader-app"
    >
      <uploader-unsupport></uploader-unsupport>

      <uploader-btn id="global-uploader-btn" ref="uploadBtn">选择文件</uploader-btn>

      <uploader-list v-show="panelShow">
        <div class="file-panel" slot-scope="props" :class="{ collapse: collapse }">
          <div class="file-title">
            <div class="title">文件列表</div>
            <div class="operate">
              <el-button
                @click="collapse = !collapse"
                type="text"
                :title="collapse ? '展开' : '折叠'"
              >
                <i class="iconfont" :class="collapse ? 'el-icon-full-screen' : 'el-icon-minus'"></i>
              </el-button>
              <el-button v-if="!props.fileList||props.fileList.length==0" @click="close" type="text" title="关闭">
                <i class="iconfont el-icon-close"></i>
              </el-button>
            </div>
          </div>

          <ul class="file-list">
            <li class="file-item" v-for="file in props.fileList" :key="file.id">
              <uploader-file
                :class="['file_' + file.id, customStatus]"
                ref="files"
                :file="file"
                :list="true"
              ></uploader-file>
            </li>
            <div class="no-file" v-if="!props.fileList.length">
              <i class="iconfont icon-empty-file"></i> 暂无待上传文件
            </div>
          </ul>
        </div>
      </uploader-list>
    </uploader>
  </div>
</template>

<script>
/**
 *  全局上传插件，两种调用方式
 *   1. 作为全局页面的组件，使用event bus
 *   调用方法：Bus.$emit('openSimpleUpload', {params: {}, options: {}})
 *               params: 发送给服务器的额外参数；
 *               options：上传选项，目前支持 target、testChunks、mergeFn、accept
 *
 *   监听函数：Bus.$on('fileSimpleUploadAdded', fn); 文件选择后的回调
 *           Bus.$on('fileSimpleUploadSuccess', fn); 文件上传成功的回调，监听后记得释放
 *
 *   2. 作为普通组件在单个页面中调用，使用props
 */
import {ACCEPT_CONFIG} from './js/config'
import Bus from './js/bus'
import SparkMD5 from 'spark-md5'
import {Message} from "element-ui";

export default {
  props: {
    global: {
      type: Boolean,
      default: true
    },
    // 发送给服务器的额外参数
    params: {
      type: Object
    },
    options: {
      type: Object
    }
  },

  data() {
    return {
      initOptions: {
        target: '/api/party/oss/uploadChunk',
        //参数对象
        processParams:(file)=> {
          return {
            chunkNumber: file.chunkNumber - 1,
            totalChunks: file.totalChunks,
            fileName: file.filename,
            identifier: file.identifier,
          }
        },
        // 是否开启服务器分片校验
        testChunks: false,
        // 分块时按照该值来分，最后一个上传块的大小是可能是大于等于1倍的这个值但是小于两倍的这个值大小
        chunkSize: 1024 * 1024 * 10,
        forceChunkSize: true,
        // 并发上传数
        simultaneousUploads: 1,
        // 上传文件时文件的参数名
        fileParameterName: 'file',
        // 最大自动失败重试上传次数，值可以是任意正整数，如果是 undefined 则代表无限次
        maxChunkRetries: 0,
        // 是否选择文件后自动开始上传
        autoStart: false,
        // 如果说一个文件已经上传过了是否还允许再次上传。默认的话如果已经上传了，除非你移除了否则是不会再次重新上传的
        allowDuplicateUploads: true,
        // 认为响应式成功的响应码
        successStatuses: [200, 0],
        // 服务器分片校验函数，秒传及断点续传基础
        checkChunkUploadedByResponse: function (chunk, message) {
          let skip = false;
          try {
            let objMessage = JSON.parse(message)
            if (objMessage.code == 0 && objMessage.data.url && objMessage.data.url != '') {
              skip = true;
            } else {
                skip = false;
              }
            } catch (e) {
            }
            return skip
        },
        query: (file, chunk) => {
          return {
            ...file.params
          }
        }
      },
      fileStatusText: {
        success: '上传成功',
        error: '上传失败',
        uploading: '上传中',
        paused: '生成中等待上传',
        waiting: '等待上传'
      },
      panelShow: false, //选择文件后，展示上传panel
      collapse: false,
      customParams: {},
      customStatus: '',
      fileDataList: []
    }
  },

  watch: {
    params: {
      handler(data) {
        if (data) {
          this.customParams = data
        }
      },
      immediate: true
    },
    options: {
      handler(data) {
        if (data) {
          setTimeout(() => {
            this.customizeOptions(data)
          }, 0)
        }
      },
      immediate: true
    }
  },

  mounted() {
    //打开选择文件
    Bus.$on('openSimpleUpload', ({params={}, options={}}) => {
      this.customParams = params
      this.customizeOptions(options);
      if (this.$refs.uploadBtn) {
        this.$refs.uploadBtn.$el.click()
      }
    })
    //关闭上传
    Bus.$on('closeSimpleUpload', ({}) => {
      this.close();
    })

  },

  computed: {
    // Uploader实例
    uploader() {
      return this.$refs.uploader.uploader
    }
  },

  methods: {
    /**
     * 自定义options,url
     * @param opts
     */
    customizeOptions(opts) {
      // 自定义上传url
      if (opts.target) {
        this.uploader.opts.target = opts.target
      }

      // 是否可以秒传、断点续传
      if (opts.testChunks !== undefined) {
        this.uploader.opts.testChunks = opts.testChunks
      }

      // merge 的方法，类型为Function，返回Promise mergeSimpleUpload
      this.mergeFn = opts.mergeFn || ''

      // 自定义文件上传类型
      let input = document.querySelector('#global-uploader-btn input')
      let accept = opts.accept || ACCEPT_CONFIG.videoMp4;
      input.setAttribute('accept', accept.join())
    },

    /**
     * 上传视频前检验
     * @param file
     */
    beforeVideoUpload(file) {
      console.log(file.fileType,'file.type')
      let fileSize = file.size / 1024 / 1024 / 1024 < 2;   //控制大小
      if (!fileSize) {
        Message.error("视频大小不能超过2G");
        return false;
      }
      if (
        [
          "video/mp4",
          "video/mov",
          "video/quicktime"
        ].indexOf(file.fileType) == -1     //控制格式
      ) {
        Message.error("请上传正确的视频格式mp4/mov");
        return false;
      }
      return  true;
    },
    /**
     * 文件上传- 选择文件
     * @param file
     */
    onFileAdded(file) {
      // 将额外的参数赋值到每个文件上，以不同文件使用不同params的需求
      file.params = this.customParams
      if (!this.beforeVideoUpload(file)) {
        file.pause();
        file.cancel();
        return
      } else {
        this.panelShow = true
        this.emit('fileSimpleUploadAdded', {file: file, data: null})
        this.fileDataList.push({file: null, data: null})
        // 计算MD5
        this.computeMD5(file).then((result) => this.startUpload(result))
      }
    },
    /**
     * 计算md5值，以实现断点续传及秒传
     * @param file
     * @returns Promise
     */
    computeMD5(file) {
      //文件实体
      let fileReader = new FileReader()
      let time = new Date().getTime();
      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
      //分片数
      let currentChunk = 0
      //每分片大小
      const chunkSize = 1024 * 1024 * 10;
      //总分片数
      let chunks = Math.ceil(file.size / chunkSize)
      let spark = new SparkMD5.ArrayBuffer()
      // 文件状态设为"计算MD5"
      this.statusSet(file.id, 'md5');
      // 暂停文件
      file.pause()
      //截取分片
      loadNext()

      return new Promise((resolve, reject) => {
        //文件读取加载
        fileReader.onload = (e) => {
          spark.append(e.target.result)

          if (currentChunk < chunks) {
            currentChunk++
            //截取分片
            let bol = loadNext();
            let doc = document.querySelector(`.custom-status-${file.id}`);
            // 实时展示MD5的计算进度
            this.$nextTick(() => {
              if(!doc){
                return;
              }
              if(bol){
                const md5ProgressText = '校验MD5 ' + (100).toFixed(0) + '%'
                doc.innerText = md5ProgressText
                let md5 = spark.end();
                // md5计算完毕
                resolve({md5, file})
              }else {
                const md5ProgressText = '校验MD5 ' + ((currentChunk / chunks) * 100).toFixed(0) + '%'
                doc.innerText = md5ProgressText
              }
            })
          } else {
            let md5 = spark.end()
            // md5计算完毕
            resolve({md5, file})
            console.log(
              `MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${file.size} 用时：${
                new Date().getTime() - time
              } ms`
            )
          }
        }

        //文件读取出错
        fileReader.onerror = function () {
          // this.error(`文件${file.name}读取出错，请检查该文件`)
          Message({
            message: `文件${file.name}读取出错，请检查该文件`,
            type: 'error',
            duration: 3000,
          })
          file.cancel()
          this.emit('fileSimpleUploadError', {
            message: `文件${file.name}读取出错，请检查该文件`,
            file: file,
            list: props.fileList
          })
          reject()
        }
      })

      /***
       * 文件流 截取分片
       */
      function loadNext() {
        //开始
        let start = currentChunk * chunkSize;
        //结束
        let end = start + chunkSize >= file.size ? file.size : start + chunkSize;

        let fileObj = (blobSlice?.call(file.file, start, end));
        if (start > end || fileObj.size == 0) {
          return true
        } else {
          fileReader.readAsArrayBuffer(fileObj)
          return false
        }
      }
    },

    /***
     * md5计算完毕，开始上传
     * @param md5
     * @param file
     */
    startUpload({md5, file}) {
      file.uniqueIdentifier = md5;
      this.fileDataList[this.fileDataList.length - 1].file = file;
      // 开始/继续文件上传
      file.resume()
      this.statusRemove(file.id)
    },

    /***
     * 文件上传成功
     * @param rootFile
     * @param file
     * @param response
     * @param chunk
     */
    onFileSuccess(rootFile, file, response, chunk) {
      let res = JSON.parse(response)
      // 服务端自定义的错误（即http状态码为200，但是是错误的情况），这种错误是Uploader无法拦截的
      if (res.code != 0) {
        this.error(res.message)
        // 文件状态设为“失败”
        this.statusSet(file.id, 'failed')
        this.emit('fileSimpleUploadError', {data: res, file: file})
        return
      }
      if (res.data&&!res.data.url||res.data.url=='') {
        // 文件状态设为“失败”
        this.statusSet(file.id, 'failed')
        this.emit('fileSimpleUploadError', {data: res, file: file})
        return
      }
      this.fileDataList[this.fileDataList.length - 1] = {data: res.data, file, chunk};
      this.emit('fileSimpleUploadSuccess', {data: res.data, file, chunk})
      setTimeout(()=>{
        file.params.bol=true;
        file.cancel(null);
      },5000)
    },

    /***
     * 文件上传 进度条
     * @param rootFile
     * @param file
     * @param chunk
     */
    onFileProgress(rootFile, file, chunk) {
      if ((chunk.endByte+chunk.chunkSize) >= chunk.file.size) {
        // 文件状态设为"转码中"
        this.statusSet(file.id, 'transcoding');
      }
    },
    /***
     * 文件上传 失败
     * @param rootFile
     * @param file
     * @param chunk
     * @param response
     */
    onFileError(rootFile, file, response, chunk) {
      this.error(response)
      this.emit('fileSimpleUploadError', {response, file: file})
    },
    /***
     * 删除文件上传
     * @param rootFile
     * @param file
     * @param chunk
     * @param response
     */
    onFileRemoved(file) {
      this.emit('fileSimpleUploadRemoved', {file: file ||null})
      // try {
        // rootFile.cancel();
      // }catch (e) {
      //
      // }
    },
    /***
     * 文件上传 关闭
     */
    close() {
      this.uploader.cancel()
      this.panelShow = false
    },
    /**
     * 新增的自定义的状态: 'md5'、'merging'、'transcoding'、'failed'
     * @param id
     * @param status
     */
    statusSet(id, status) {
      let statusMap = {
        md5: {
          text: '校验MD5',
          bgc: '#fff'
        },
        merging: {
          text: '合并中',
          bgc: '#e2eeff'
        },
        transcoding: {
          text: '转码合并中',
          bgc: '#e2eeff'
        },
        failed: {
          text: '上传失败',
          bgc: '#e2eeff'
        }
      }

      this.customStatus = status
      this.$nextTick(() => {
        const statusTag = document.createElement('p')
        statusTag.className = `custom-status-${id} custom-status`
        statusTag.innerText = statusMap[status].text
        statusTag.style.backgroundColor = statusMap[status].bgc

        const statusWrap = document.querySelector(`.file_${id} .uploader-file-status`)
        statusWrap.appendChild(statusTag)
      })
    },
    /**
     * 状态清除
     * @param id
     */
    statusRemove(id) {
      this.customStatus = ''
      this.$nextTick(() => {
        const statusTag = document.querySelector(`.custom-status-${id}`)
        statusTag.remove()
      })
    },

    /**
     * emit
     * @param e,r
     */
    emit(e,r) {
      Bus.$emit(e,r)
      this.$emit(e,r)
    },

    /**
     * error
     * @param msg
     */
    error(msg) {
      Message({
        message: `文件出错，请检查`,
        type: 'error',
        duration: 3000,
      })
      // this.$notify({
      //   title: '错误',
      //   message: msg,
      //   type: 'error',
      //   duration: 2000
      // })
    }
  }
}
</script>

<style lang="scss">
#global-uploader {
  &:not(.global-uploader-single) {
    position: fixed;
    z-index: 5000;
    right: 15px;
    bottom: 15px;
    box-sizing: border-box;
  }

  .uploader-app {
    width: 520px;
  }

  .file-panel {
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 7px 7px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 2500 !important;

    .file-title {
      display: flex;
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      border-bottom: 1px solid #ddd;
      font-size: 18px !important;

      .operate {
        flex: 1;
        text-align: right;

        i {
          font-size: 18px;
        }
      }
    }

    .file-list {
      position: relative;
      height: 240px;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: #fff;
      transition: all 0.3s;

      .file-item {
        background-color: #fff;
        font-size: 16px !important;
      }
    }

    &.collapse {
      .file-title {
        background-color: #e7ecf2;
      }
      .file-list {
        height: 0;
      }
    }
  }

  .no-file {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
  }
  .uploader-file {
    &.md5 {
      .uploader-file-resume {
        display: none;
      }
    }
  }
  .uploader-file-info{
    display: flex;
    justify-content: space-between;
  }
  .uploader-file-icon {
    &:before {
      content: '' !important;
    }

    &[icon='image'] {
      background: url(./images/image-icon.png);
    }
    &[icon=audio] {
      background: url(./images/audio-icon.png);
      background-size: contain;
    }
    &[icon='video'] {
      background: url(./images/video-icon.png);
    }

    &[icon='document'] {
      background: url(./images/text-icon.png);
    }

    &[icon=unknown] {
      background: url(./images/zip.png) no-repeat center;
      background-size: contain;
    }
  }

  .uploader-file-name {
    width: 30% !important;
  }
  .uploader-file-size {
    width: 18% !important;
  }
  .uploader-file-status{
    width: 38% !important;
  }
  .uploader-file-actions{
    width:6% !important;
  }
  .uploader-file-meta{
    width:0% !important;
  }

  .uploader-file-actions > span {
    margin-right: 6px;
  }

  .uploader-file-actions .uploader-file-pause,.uploader-file-actions .uploader-file-retry {
    display: none !important;
  }

  .custom-status {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

/* 隐藏上传按钮 */
#global-uploader-btn {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.global-uploader-single {
  #global-uploader-btn {
    position: relative;
  }
}
</style>
