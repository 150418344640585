/**
 * @name: 富文本编辑器
 * @author: itmobai
 * @date: 2023-03-17 18:55
 * @description：富文本编辑器
 * @update: 2023-03-17 18:55
 */
import WangEditor from "./WangEditor.vue"
import Vue from "vue";

// @ts-ignore
WangEditor.install = (vue: Vue) => {
  //@ts-ignore
  Vue.component(WangEditor.name, WangEditor);
}

export default WangEditor;
