/**
 * @name: 附件上传组件入口
 * @author: itmobai
 * @date: 2023-03-21 17:06
 * @description：附件上传组件入口
 * @update: 2023-03-21 17:06
 */
import FileUpload from "./FileUpload.vue"
import Vue from "vue";

// @ts-ignore
FileUpload.install = (vue: Vue) => {
  Vue.component(FileUpload.name, FileUpload);
}

export default FileUpload;
