
/**
 * @name: 图片上传组件
 * @author: itmobai
 * @date: 2023-03-17 17:24
 * @description：图片上传组件
 * @update: 2023-03-17 17:24
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import config from "@/config";
import type {IConfig} from "@/config/types";
import {getToken} from "@/utils/token";

@Component({})
export default class PictureUpload extends Vue {
  // v-model
  @Prop(String) value!: string;
  // 上传类型
  @Prop({default: 1}) type!: number;
  // 是否支持多选
  @Prop({default: false}) multiple!: boolean;
  // 上传数量
  @Prop({default: 1}) limit!: number;
  // 上传前函数
  @Prop({
    default: () => {
    }
  }) beforeUpload!: () => {}

  @Prop({
    default: () => {
    }
  }) onExceed!: () => {}

  @Watch("value")
  onValueChange(newVal: string) {
    if (newVal && !this.filelist.length) {
      let arr = newVal.split(";")
      this.filelist = arr.map((item: string) => {
        return {
          url: config.downloadUrl + item
        }
      })
    }
  }

  @Watch("filelist", {deep: true})
  onFilelistChange(newVal: { url: string }[]) {
    if (newVal && newVal.length) {
      this.$emit('input', newVal.map(item => {
        if (item.url.startsWith('blob:')) {
          // @ts-ignore
          if (item.response && item.response.code === 0) {
          // @ts-ignore
            return item.response.data.url
          }
        } else {
          return item.url.replace(config.downloadUrl, '')
        }
      }).join(';'))
    }
  }

  // 请求配置
  config: IConfig = config
  // 请求头token配置
  headers = {
    "Authorization": `Bearer ${getToken()}`
  }
  // 真实url集合
  filelist: { url: string }[] = []

  /**
   * 上传成功事件
   * @param response
   * @param file
   * @param fileList
   */
  onUploadSuccess(response: any, file: File, fileList: any) {
    this.filelist = fileList
  }

  /**
   * 文件移除时
   * @param file
   * @param fileList
   */
  onUploadRemove(file: any, fileList: any) {
    this.filelist = fileList
  }

  created () {
    console.log(111)
    // 获取初始值
    if (this.value) {
      let arr = this.value.split(";")
      this.filelist = arr.map((item: string) => {
        return {
          url: config.downloadUrl + item
        }
      })
    } else {
      this.filelist = []
    }

  }
}
