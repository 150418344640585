<script>
/**
 * @name: 富文本编辑器
 * @author: itmobai
 * @date: 2023-03-17 18:55
 * @description：富文本编辑器
 * @update: 2023-03-17 18:55
 */
import { uploadFile } from "@/request"
import config from "@/config";
import E from 'wangeditor'

export default {
  props: {
    value: String,
    type: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal !== this.editor.txt.html()) {
          if (!newVal) {
            this.editor.txt.html("")
          } else {
            this.editor.txt.html(newVal)
          }
        }
      }
    },
    html(newVal) {
      this.$emit('input', newVal)
    }
  },
  data() {
    let that = this;
    return {
      editor: null,
      info_: "",
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor)
    }
  },
  mounted() {
    this.editor = new E(this.$refs.editor)
    this.editor.config.height = 300;
    this.editor.config.onchange = (html) => {
      this.info_ = html
      this.$emit('input', this.info_) // 将内容同步到父组件中
    }
    this.editor.config.uploadImgMaxLength = 1
    this.editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
      try {
        let formData = new FormData();
        formData.append("file", resultFiles[0])
        formData.append("type", this.type);
        const res = await uploadFile(formData);
        // let url = `${location.protocol}//${location.hostname}:${location.port}${fileConstant.file_prefix}${res.url}`
        let url = `${config.downloadUrl}${res.url}`
        insertImgFn(url)
      } catch (err) {
        console.log(err)
      }
    }

    // 取消自动 focus
    this.editor.config.focus = false
    // 创建富文本编辑器
    this.editor.create()
    if (this.disabled) {
      this.editor.disable();
    } else {
      this.editor.enable();
    }
    // this.editor.disable();

    this.editor.txt.html(this.value)
  },
  beforeDestroy() {
    if (this.editor == null) return
    this.editor.destroy()
  }
}
</script>

<template>
  <div style="border: 1px solid #ccc; width: 100%; position: relative; z-index: 100 !important;">
    <div ref="editor"></div>
  </div>
</template>

<style lang="scss" scoped>

</style>
