const menusList =
  [
    {
      "id": 1, "pid": 0, "sort": 0, "path": "/home/index", "name": "主页", "redirect": null,
      "component": "/home/index.vue",
      "meta": {
        "title": "主页",
        "isLink": null,
        "isHide": false,
        "isKeepAlive": false,
        "isAffix": false,
        "isIframe": false,
        "auth": null,
        "icon": 'el-icon-s-home'
      },
      "children": []
    },
    {
      "id": 9,
      "pid": 0,
      "sort": 1,
      "path": "/customer",
      "name": "客户管理",
      "redirect": null,
      "component": "/customer/member.vue",
      "meta": {
        "title": "客户管理",
        "isLink": null,
        "isHide": false,
        "isKeepAlive": false,
        "isAffix": false,
        "isIframe": false,
        "auth": null,
        "icon": 'el-icon-s-cooperation'
      },
      "children": [
        {
          "id": 91,
          "pid": 9,
          "sort": 1,
          "name": "会员管理",
          "redirect": null,
          "path": "/customer/member",
          "component": "/customer/member.vue",
          "meta": {
            "title": "会员管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-user'
          },
          "children": []
        },
        {
          "id": 92,
          "pid": 9,
          "sort": 2,
          "name": "会员等级",
          "redirect": null,
          "path": "/customer/level",
          "component": "/customer/level.vue",
          "meta": {
            "title": "会员等级",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-user'
          },
          "children": []
        }
      ]
    },
    {
      "id": 60,
      "pid": 0,
      "sort": 2,
      "name": "订单管理",
      "redirect": null,
      "path": "/order/shop",
      "component": "/order/shop.vue",
      "meta": {
        "title": "订单管理",
        "isLink": null,
        "isHide": false,
        "isKeepAlive": false,
        "isAffix": false,
        "isIframe": false,
        "auth": null,
        "icon": 'el-icon-s-order'
      },
      "children": [
        {
          "id": 601,
          "pid": 60,
          "sort": 6,
          "name": "商城订单管理",
          "redirect": null,
          "path": "/order/shop",
          "component": "/order/shop.vue",
          "meta": {
            "title": "商城订单管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-shop'
          },
          "children": []
        },
        {
          "id": 602,
          "pid": 60,
          "sort": 6,
          "name": "旅游订单管理",
          "redirect": null,
          "path": "/order/travel",
          "component": "/order/travel.vue",
          "meta": {
            "title": "旅游订单管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-fold'
          },
          "children": []
        },
        {
          "id": 603,
          "pid": 60,
          "sort": 6,
          "name": "赛事订单管理",
          "redirect": null,
          "path": "/order/match",
          "component": "/order/match.vue",
          "meta": {
            "title": "赛事订单管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-unfold'
          },
          "children": []
        },
        {
          "id": 604,
          "pid": 60,
          "sort": 6,
          "name": "1v1课程订单管理",
          "redirect": null,
          "path": "/order/one",
          "component": "/order/one.vue",
          "meta": {
            "title": "1v1课程订单管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-operation'
          },
          "children": []
        },
        {
          "id": 605,
          "pid": 60,
          "sort": 6,
          "name": "网红订单管理",
          "redirect": null,
          "path": "/order/internet",
          "component": "/order/internet.vue",
          "meta": {
            "title": "网红订单管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-tickets'
          },
          "children": []
        },
        {
          "id": 606,
          "pid": 60,
          "sort": 6,
          "name": "视频课程订单管理",
          "redirect": null,
          "path": "/order/lesson",
          "component": "/order/lesson.vue",
          "meta": {
            "title": "视频课程订单管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-document'
          },
          "children": []
        }
      ]
    },
    {
      "id": 11,
      "pid": 0,
      "sort": 3,
      "path": "/productManage",
      "name": "productManage",
      "redirect": null,
      "component": "/productManage/product/index.vue",
      "meta": {
        "title": "商品管理",
        "isLink": null,
        "isHide": false,
        "isKeepAlive": false,
        "isAffix": false,
        "isIframe": false,
        "auth": null,
        "icon": 'el-icon-s-management'
      },
      "children": [
        {
          "id": 31,
          "pid": 11,
          "sort": 2,
          "name": "product",
          "redirect": null,
          "path": "/productManage/product",
          "component": "/productManage/product/index.vue",
          "meta": {
            "title": "商品管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-menu'
          },
          "children": []
        },
        {
          "id": 113,
          "pid": 11,
          "sort": 9,
          "name": "productSort",
          "redirect": null,
          "path": "/productManage/classification",
          "component": "/productManage/classification.vue",
          "meta": {
            "title": "商品分类",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-grid'
          },
          "children": []
        },
        {
          "id": 114,
          "pid": 11,
          "sort": 9,
          "name": "productSku",
          "redirect": null,
          "path": "/productManage/specification",
          "component": "/productManage/specification.vue",
          "meta": {
            "title": "商品规格",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-operation'
          },
          "children": []
        },
        {
          "id": 119,
          "pid": 11,
          "sort": 9,
          "name": "productBrand",
          "redirect": null,
          "path": "/productManage/brand",
          "component": "/productManage/brand.vue",
          "meta": {
            "title": "商品品牌",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-operation'
          },
          "children": []
        },
        {
          "id": 32,
          "pid": 11,
          "sort": 3,
          "name": "addProduct",
          "redirect": null,
          "path": "/productManage/addProduct",
          "component": "/productManage/product/addProduct.vue",
          "meta": {
            "title": "商品新增/编辑",
            "isLink": null,
            "isHide": true,
            "isKeepAlive": true,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-operation'
          },
          "children": []
        },
      ]
    },
    {
      "id": 6,
      "pid": 0,
      "sort": 3,
      "path": "/travelManage",
      "name": "travelManage",
      "redirect": null,
      "component": "/travelManage/index.vue",
      "meta": {
        "title": "旅游管理",
        "isLink": null,
        "isHide": false,
        "isKeepAlive": false,
        "isAffix": false,
        "isIframe": false,
        "auth": null,
        "icon": 'el-icon-notebook-1'
      },
      "children": [
        {
          "id": 61,
          "pid": 6,
          "sort": 2,
          "name": "旅游管理",
          "redirect": null,
          "path": "/travelManage/index",
          "component": "/travelManage/index.vue",
          "meta": {
            "title": "旅游管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-data'
          },
          "children": []
        },
        {
          "id": 31,
          "pid": 2,
          "sort": 2,
          "name": "travelManageAdd",
          "redirect": null,
          "path": "/travelManage/add",
          "component": "/travelManage/add.vue",
          "meta": {
            "title": "旅游管理新增/编辑",
            "isLink": null,
            "isHide": true,
            "isKeepAlive": true,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-user'
          },
          "children": []
        },

        {
          "id": 36,
          "pid": 2,
          "sort": 3,
          "name": "旅游规格",
          "redirect": null,
          "path": "/travelManage/specification",
          "component": "/travelManage/specification.vue",
          "meta": {
            "title": "旅游规格",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-operation'
          },
          "children": []
        },
        {
          "id": 37,
          "pid": 2,
          "sort": 3,
          "name": "travelAddress",
          "redirect": null,
          "path": "/travelManage/address",
          "component": "/travelManage/address.vue",
          "meta": {
            "title": "旅游地址标签",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-flag'
          },
          "children": []
        },
      ]
    },
    {
      "id": 6,
      "pid": 0,
      "sort": 3,
      "path": "/competitionManage",
      "name": "competitionManage",
      "redirect": null,
      "component": "/competitionManage/index.vue",
      "meta": {
        "title": "赛事管理",
        "isLink": null,
        "isHide": false,
        "isKeepAlive": false,
        "isAffix": false,
        "isIframe": false,
        "auth": null,
        "icon": 'el-icon-data-analysis'
      },
      "children": [
        {
          "id": 326,
          "pid": 21,
          "sort": 2,
          "name": "competitionManage",
          "redirect": null,
          "path": "/competitionManage/index",
          "component": "/competitionManage/index.vue",
          "meta": {
            "title": "赛事管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-data'
          },
          "children": []
        },
        {
          "id": 3226,
          "pid": 221,
          "sort": 4,
          "name": "competitionManageAdd",
          "redirect": null,
          "path": "/competitionManage/add",
          "component": "/competitionManage/add.vue",
          "meta": {
            "title": "赛事管理新增/编辑",
            "isLink": null,
            "isHide": true,
            "isKeepAlive": true,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-user'
          },
          "children": []
        },
      ]
    },
    {
      "id": 4,
      "pid": 0,
      "sort": 3,
      "path": "/coursesManage",
      "name": "coursesManage",
      "redirect": null,
      "component": "/coursesManage/video/index.vue",
      "meta": {
        "title": "课程管理",
        "isLink": null,
        "isHide": false,
        "isKeepAlive": false,
        "isAffix": false,
        "isIframe": false,
        "auth": null,
        "icon": 'el-icon-c-scale-to-original'
      },
      "children": [
        {
          "id": 51,
          "pid": 5,
          "sort": 2,
          "name": "video",
          "redirect": null,
          "path": "/coursesManage/video/index",
          "component": "/coursesManage/video/index.vue",
          "meta": {
            "title": "视频课程",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-data'
          },
          "children": []
        },
        {
          "id": 52,
          "pid": 5,
          "sort": 2,
          "name": "videoAdd",
          "redirect": null,
          "path": "/coursesManage/video/add",
          "component": "/coursesManage/video/add.vue",
          "meta": {
            "title": "视频课程新增/编辑",
            "isLink": null,
            "isHide": true,
            "isKeepAlive": true,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-user'
          },
          "children": []
        },
        {
          "id": 53,
          "pid": 5,
          "sort": 2,
          "name": "internetCelebrity",
          "redirect": null,
          "path": "/coursesManage/internetCelebrity/index",
          "component": "/coursesManage/internetCelebrity/index.vue",
          "meta": {
            "title": "网红陪玩",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-data-line'
          },
          "children": []
        },
        {
          "id": 53,
          "pid": 5,
          "sort": 2,
          "name": "internetCelebrityAdd",
          "redirect": null,
          "path": "/coursesManage/internetCelebrity/add",
          "component": "/coursesManage/internetCelebrity/add.vue",
          "meta": {
            "title": "网红陪玩新增/编辑",
            "isLink": null,
            "isHide": true,
            "isKeepAlive": true,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-user'
          },
          "children": []
        },
        {
          "id": 53,
          "pid": 6,
          "sort": 3,
          "name": "coach",
          "redirect": null,
          "path": "/coursesManage/coach/index",
          "component": "/coursesManage/coach/index.vue",
          "meta": {
            "title": "1v1教练课程",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-user'
          },
          "children": []
        },
        {
          "id": 54,
          "pid": 7,
          "sort": 4,
          "name": "coachAdd",
          "redirect": null,
          "path": "/coursesManage/coach/add",
          "component": "/coursesManage/coach/add.vue",
          "meta": {
            "title": "1v1教练课程新增/编辑",
            "isLink": null,
            "isHide": true,
            "isKeepAlive": true,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-user'
          },
          "children": []
        },
      ]
    },

    {
      "id": 21,
      "pid": 0,
      "sort": 3,
      "path": "/marketingManage",
      "name": "marketingManage",
      "redirect": null,
      "component": "/marketingManage/banners/index.vue",
      "meta": {
        "title": "营销管理",
        "isLink": null,
        "isHide": false,
        "isKeepAlive": false,
        "isAffix": false,
        "isIframe": false,
        "auth": null,
        "icon": 'el-icon-s-marketing'
      },
      "children": [
        {
          "id": 31,
          "pid": 2,
          "sort": 2,
          "name": "Brand",
          "redirect": null,
          "path": "/marketingManage/brand",
          "component": "/marketingManage/brand.vue",
          "meta": {
            "title": "品牌故事",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-picture-outline-round'
          },
          "children": []
        },
        {
          "id": 31,
          "pid": 2,
          "sort": 2,
          "name": "Banner",
          "redirect": null,
          "path": "/marketingManage/banner",
          "component": "/marketingManage/banner/index.vue",
          "meta": {
            "title": "小程序首页轮播图",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-picture'
          },
          "children": []
        },
        {
          "id": 31,
          "pid": 2,
          "sort": 2,
          "name": "Rules",
          "redirect": null,
          "path": "/marketingManage/rules",
          "component": "/marketingManage/rules.vue",
          "meta": {
            "title": "平台规则",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-tools'
          },
          "children": []
        },
        {
          "id": 31,
          "pid": 2,
          "sort": 2,
          "name": "walletRules",
          "redirect": null,
          "path": "/marketingManage/walletRules",
          "component": "/marketingManage/walletRules.vue",
          "meta": {
            "title": "钱包充值规则",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-wallet'
          },
          "children": []
        },
        {
          "id": 34,
          "pid": 2,
          "sort": 3,
          "name": "marketingShop",
          "redirect": null,
          "path": "/marketingManage/shop",
          "component": "/marketingManage/shop.vue",
          "meta": {
            "title": "小程序推荐商品",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-news'
          },
          "children": []
        },
        {
          "id": 31,
          "pid": 2,
          "sort": 2,
          "name": "thematicActivities",
          "redirect": null,
          "path": "/marketingManage/thematicActivities",
          "component": "/marketingManage/thematicActivities.vue",
          "meta": {
            "title": "专题活动",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-data-board'
          },
          "children": []
        },
        {
          "id": 33,
          "pid": 2,
          "sort": 3,
          "name": "marketingMatch",
          "redirect": null,
          "path": "/marketingManage/match",
          "component": "/marketingManage/match.vue",
          "meta": {
            "title": "小程序热门赛事",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-magic-stick'
          },
          "children": []
        },
        {
          "id": 33,
          "pid": 2,
          "sort": 3,
          "name": "marketingTravel",
          "redirect": null,
          "path": "/marketingManage/travel",
          "component": "/marketingManage/travel.vue",
          "meta": {
            "title": "小程序高尔夫旅游",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-bangzhu'
          },
          "children": []
        },
        {
          "id": 389,
          "pid": 2,
          "sort": 3,
          "name": "marketingCoach",
          "redirect": null,
          "path": "/marketingManage/coach",
          "component": "/marketingManage/coach.vue",
          "meta": {
            "title": "小程序人气教练",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-guide'
          },
          "children": []
        },
        {
          "id": 31,
          "pid": 2,
          "sort": 2,
          "name": "thematicAdd",
          "redirect": null,
          "path": "/marketingManage/thematicAdd",
          "component": "/marketingManage/thematicAdd.vue",
          "meta": {
            "title": "专题活动",
            "isLink": null,
            "isHide": true,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-user'
          },
          "children": []
        },
      ]
    },

    {
      "id": 3,
      "pid": 0,
      "sort": 3,
      "path": "/distributionManage",
      "name": "分销管理",
      "redirect": null,
      "component": "/distributionManage/coachManage.vue",
      "meta": {
        "title": "分销管理",
        "isLink": null,
        "isHide": false,
        "isKeepAlive": false,
        "isAffix": false,
        "isIframe": false,
        "auth": null,
        "icon": 'el-icon-collection'
      },
      "children": [
        {
          "id": 31,
          "pid": 2,
          "sort": 2,
          "name": "教练管理",
          "redirect": null,
          "path": "/distributionManage/coachManage",
          "component": "/distributionManage/coachManage.vue",
          "meta": {
            "title": "教练管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-fold'
          },
          "children": []
        },
        {
          "id": 31,
          "pid": 2,
          "sort": 2,
          "name": "talentManage",
          "redirect": null,
          "path": "/distributionManage/talentManage",
          "component": "/distributionManage/talentManage.vue",
          "meta": {
            "title": "达人管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-unfold'
          },
          "children": []
        },
        {
          "id": 31,
          "pid": 2,
          "sort": 2,
          "name": "channelManage",
          "redirect": null,
          "path": "/distributionManage/channelManage",
          "component": "/distributionManage/channelManage.vue",
          "meta": {
            "title": "渠道方管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-s-operation'
          },
          "children": []
        },
        {
          "id": 39,
          "pid": 2,
          "sort": 2,
          "name": "distributionManageRule",
          "redirect": null,
          "path": "/distributionManage/rule",
          "component": "/distributionManage/rule.vue",
          "meta": {
            "title": "提成规则",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-user'
          },
          "children": []
        },
      ]
    },



    {
      "id": 22,
      "pid": 0,
      "sort": 6,
      "path": "/finance",
      "name": "财务结算",
      "redirect": null,
      "component": "/finance/coachReconcile.vue",
      "meta": {
        "title": "财务结算",
        "isLink": null,
        "isHide": false,
        "isKeepAlive": false,
        "isAffix": false,
        "isIframe": false,
        "auth": null,
        "icon": 'el-icon-s-finance'
      },
      "children": [
        {
          "id": 23,
          "pid": 2,
          "sort": 1,
          "name": "coachReconcile",
          "redirect": null,
          "path": "/finance/coachReconcile",
          "component": "/finance/coachReconcile.vue",
          "meta": {
            "title": "教练佣金结算",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-brush'
          },
          "children": []
        },
        {
          "id": 23,
          "pid": 2,
          "sort": 1,
          "name": "expert",
          "redirect": null,
          "path": "/finance/expert",
          "component": "/finance/expert.vue",
          "meta": {
            "title": "达人佣金结算",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-pie-chart'
          },
          "children": []
        },
        {
          "id": 23,
          "pid": 2,
          "sort": 1,
          "name": "channel",
          "redirect": null,
          "path": "/finance/channel",
          "component": "/finance/channel.vue",
          "meta": {
            "title": "渠道方佣金结算",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-data-line'
          },
          "children": []
        },

      ]
    },
    {
      "id": 2,
      "pid": 0,
      "sort": 2,
      "path": "/systemManage",
      "name": "系统管理",
      "redirect": null,
      "component": "/systemManage/account.vue",
      "meta": {
        "title": "系统管理",
        "isLink": null,
        "isHide": false,
        "isKeepAlive": false,
        "isAffix": false,
        "isIframe": false,
        "auth": null,
        "icon": 'el-icon-s-tools'
      },
      "children": [
        {
          "id": 21,
          "pid": 2,
          "sort": 2,
          "name": "账号管理",
          "redirect": null,
          "path": "/systemManage/account",
          "component": "/systemManage/account.vue",
          "meta": {
            "title": "账号管理",
            "isLink": null,
            "isHide": false,
            "isKeepAlive": false,
            "isAffix": false,
            "isIframe": false,
            "auth": null,
            "icon": 'el-icon-user'
          },
          "children": []
        },
      ]
    },
  ];
export default  menusList;
