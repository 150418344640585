/**
 * @name: map组件入口
 * @author: itmobai
 * @date: 2023-04-21 14:04
 * @description：map组件入口
 * @update: 2023-04-21 14:04
 */
import Map from "./Map.vue"
import Vue from "vue";

// @ts-ignore
Map.install = (vue: Vue) => {
  // @ts-ignore
  Vue.component(Map.name, Map);
}

export default Map;
