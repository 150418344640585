export default {

  filters: {
    //	标的状态（0 即将开始 1 正在进行 2 成交 3 流拍）
    $_filterStatus: (val) => {
      switch (val) {
        case 0:
          return '即将开始'
          break;
        case 1:
          return '正在进行'
          break;
        case 2:
          return '成交'
          break;
        case 3:
          return '流拍'
          break;
        default:
          break;
      }
    },
  }
}
