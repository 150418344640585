/**
 * @name: 网络请求封装
 * @author: itmobai
 * @date: 2023-02-16 11:53
 * @description：网络请求封装
 * @update: 2023-02-16 11:53
 */
import type {AxiosRequestConfig, AxiosResponse, ResponseType} from 'axios';
import axios from 'axios';
import {Message,MessageBox} from 'element-ui'
import useUserStore from '@/store/user/index';
import cusConfig from "@/config"
import store from "@/store";
import {removeToken} from "@/utils/token";
import {removeEmpty} from "@/utils/common";

export interface HttpResponse<T = unknown> {
  message: string;
  code: number;
  data?: T;
}

const axiosInstance = axios.create({
  // 请求url前缀
  baseURL: cusConfig.httpPrefix,
  // 请求超时时间
  timeout: cusConfig.timeOut
})

// 请求拦截器
axiosInstance.interceptors.request.use(
  config => {
    // 获取token
    // @ts-ignore
    if (store.getters.token) {
      // 添加token到请求头
      // @ts-ignore
      config.headers.Authorization = `Bearer ${store.getters.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * blob转字符串
 * @param blob
 */
const blobToStr = (blob: Blob) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.readAsText(blob, 'utf-8');
    reader.onload = function (e) {
      resolve(reader.result)
    }
  })
}

// 响应拦截器
axiosInstance.interceptors.response.use(
   async (response: AxiosResponse<any>) => {
    if (response.status == 200 && response.data.code == 0) {
      // 接口正常
      return response.data
    }
    if(response.request.responseType == "blob") {
      if (response.data.type == "application/json") {
        let blobStr = JSON.parse(await blobToStr(response.data) as string)
        Message.error(blobStr.message || '系统错误，请联系管理员');
        return Promise.reject(blobStr);
      }
      return response.data
    }
    const res = response.data;
    // 接口异常
    if (res.code != 0) {
      Message({
        message: res.message || "网络请求失败",
        type: 'error',
        duration: 5 * 1000,
      })
      // 未登录/token异常
      if (res.code == 401) {
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          store.dispatch("useUserStore/logOut").then(() => {
              location.href = '/login';
            })

        }).catch(() => {
          // isRelogin.show = false;
        });
      }
      return Promise.reject(res);
    }
  },
  (error) => {
    let {message} = error;
    if (message == "Network Error") {
      message = "网络连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message || "网络请求失败",
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

/**
 * http 请求request
 * @param config 请求配置
 */
const request = <T = any>(config: AxiosRequestConfig): Promise<T> => {
  return new Promise((resolve, reject) => {
    axiosInstance.request<any, AxiosResponse<HttpResponse>>(config).then((res: AxiosResponse<HttpResponse>) => {
      if (config.responseType == "blob") {
        resolve(res as any)
      }
      if (res.data) {
        // @ts-ignore
        resolve(res.data as T)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * http get请求
 * @param url 请求地址
 * @param params 请求参数
 * @param resType 返回数据类型
 */
const get = <T = any>(url: string, params?: any, resType?: ResponseType): Promise<T> => {
  removeEmpty(params)
  return request({
    url,
    params,
    responseType: resType || "json"
  })
}

/**
 * http post json数据
 * @param url 请求地址
 * @param data 请求参数
 * @param resType 返回数据类型
 */
const postJ = <T = any>(url: string, data?: any, resType?: ResponseType): Promise<T> => request({
  url,
  method: "POST",
  data,
  responseType: resType || "json",
  headers: {'Content-Type': 'application/json'}
})

/**
 * http post form数据
 * @param url 请求地址
 * @param data 请求参数
 * @param resType 返回数据类型
 */
const postW = <T = any>(url: string, data?: any, resType?: ResponseType): Promise<T> => request({
  url,
  method: "POST",
  data,
  responseType: resType || "json",
  headers: {'Content-Type': 'application/x-www-form-urlencoded'}
})

/**
 * http delete 请求
 * @param url 请求地址
 * @param data 请求参数
 */
const del = <T = any>(url: string, data?: any): Promise<T> => request({url, method: "delete", data})

/**
 * http put 请求
 * @param url 请求地址
 * @param data 请求参数
 */
const put = <T = any>(url: string, data?: any): Promise<T> => request({url, method: "put", data})

/**
 * 上传文件
 * @param formData formData对象
 */
const uploadFile = (formData: FormData) => request({
  url: cusConfig.uploadUrl,
  method: "POST",
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  data: formData
})

export {
  get,
  postJ,
  postW,
  del,
  put,
  uploadFile
}

export default request
