import {get, postJ} from "@/request";
import {ILoginParam,ISystemAdmin,IMenu} from "@/apis/core/types";
import {IPageRes} from "@/apis/page";

/**
 * 获取后端菜单
 */
export const getMenuApi = () => get<IMenu[]>("/operation/admin/getMenuTree")
/**
 * 用户登录
 * @param id 用户data
 */
export const userLoginApi = (data: ILoginParam) => get("/golf/admin/login", data)
/**
 * 获取用户信息
 * @param id
 */
export const getAccountInfoApi = () => get("/golf/admin/getAccountInfo")


/**
* 获取用户列表
*/
export const getSystemAdminApi = (param: ISystemAdmin) => get<IPageRes<ISystemAdmin[]>>("/operation/systemAccount/query", param)
/**
* 添加用户
*/
export const systemAdminCreateApi = (data: ISystemAdmin) => postJ("/operation/systemAccount/create",data)
/**
* 修改用户
*/
export const systemAdminModifyApi = (data: ISystemAdmin) => postJ("/operation/systemAccount/modify", data)
/**
* 删除用户
* @param id 用户id
*/
export const systemAdminRemoveApi = (id: string) => get("/operation/systemAccount/remove/" + id)
/**
 * 用户详情
 * @param id 用户id
 * @returns
 */
export const systemAdminDetailApi = (id: string) => get("/operation/systemAccount/detail/" + id)
/**
 * 重置密码
 * @param id 用户id
 * @returns
 */
export const systemAccountReset = (id: string) => postJ("/operation/systemAccount/reset", {id})
