import Vue from 'vue'
import '@babel/polyfill'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import MUI from "m-ui-crud"
import 'm-ui-crud/lib/m-ui.css'
import '@/assets/styles/index.scss';
import uploader from 'vue-simple-uploader'
// 分页组件
import Pagination from "@/components/Pagination/index.vue"
// 视频上传
import VideoUpload from "@/mui/components/VideoUpload";
// 图片上传
import PictureUpload from "@/mui/components/PictureUpload";
// 富文本
import WangEditor from "@/mui/components/WangEditor";
// 附件上传
import FileUpload from "@/mui/components/FileUpload"
// 商品价格修改附件上传
import ProductPriceFile from "@/mui/components/ProductPriceFile"
// 地图组件
import Map from "@/mui/components/Map"
// 自定义上传图片组件
import CusPictureUpload from "@/mui/components/CusPictureUpload"

// @ts-ignore
import filter from "./mixins/filters"
// import VideoPlayer from 'vue-video-player'
// require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
// Vue.use(VideoPlayer)
// // 这个是为了兼容 m3u8
// const hls = require('videojs-contrib-hls')
// Vue.use(hls)
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(MUI)
Vue.use(uploader)
Vue.component('Pagination', Pagination)
Vue.component('VideoUpload', VideoUpload)
Vue.component('PictureUpload', PictureUpload)
Vue.component('WangEditor', WangEditor)
Vue.component("FileUpload", FileUpload)
Vue.component("ProductPriceFile", ProductPriceFile)
Vue.component("Map", Map)
Vue.component("CusPictureUpload", CusPictureUpload)

// Vue.component("SimpleUploader", SimpleUploader)

Vue.mixin(filter)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
