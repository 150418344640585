<template>
  <div id="app">
    <router-view/>
    <!-- 将上传组件全局注册 -->
    <simple-upload />
  </div>
</template>
<script >

import SimpleUpload from './mui/components/SimpleUpload/SimpleUpload'
export default {
  components:{SimpleUpload},
  data() {
    return {}
  },
}
</script>
<style lang="scss">
</style>
