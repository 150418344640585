/**
 * @name: 附件上传组件入口
 * @author: itmobai
 * @date: 2023-03-21 17:06
 * @description：附件上传组件入口
 * @update: 2023-03-21 17:06
 */
import ProductPriceFile from "./ProductPriceFile.vue"
import Vue from "vue";

// @ts-ignore
ProductPriceFile.install = (vue: Vue) => {
  Vue.component(ProductPriceFile.name, ProductPriceFile);
}

export default ProductPriceFile;
